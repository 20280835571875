<template>
    <component 
        :is="as" 
        ref="elementRef" 
        @click="doDelete" 
        :class="className" 
        :title="_allowDelete ? $t('Delete record') : ''" 
        :disabled="confirmShown || isDeleting || (_dataObject && !_allowDelete) || isNewRecord" 
        :style="{ 'cursor': !isDeleting ? 'pointer' : 'inherit' }"
    >
        <div class="spinner-border spinner-border-sm me-1" role="status" v-if="props.dataObject && isDeleting && as === 'button'" >
            <span class="sr-only"></span>
        </div>
        <slot :isDeleting="isDeleting" :confirmShown="confirmShown">
            <template v-if="as !== 'i' && as !== 'img'">
                {{ $t('Delete') }}
            </template>
        </slot>
    </component>
</template>

<script setup>
/**
* Renders delete button with spinner while running.
* Need to provide dataObject or row, if data object provided will delete current row.
 Add default slot with text or/and icon to override default.
*@definition
*/
import { useAttrs, computed, ref } from 'vue';
import o365_confirm from 'o365.controls.confirm.ts';
import translate from 'o365.modules.translate.ts';
import logger from 'o365.modules.Logger.ts';

import { getDataObjectById } from 'o365.vue.ts'

const props = defineProps({
    dataObject: {
        type: Object,
        default: undefined
    },
    row: {
        type: Object,
        default: undefined
    },
    confirm: {
        type: Boolean,
        default: false
    },
    softDelete: {
        type: Boolean,
        default: false,
    },
    confirmOptions: {
        type: Object
    },
    as: {
        type: String,
        default: "button"
    },
});

const attrs = useAttrs();
const elementRef = ref(null);

const className = computed(() => {
    return attrs.class ? '' : 'btn btn-sm btn-link';
});

const _dataObject = computed(() => {
    if (props.dataObject) {
        return props.dataObject
    } else {
        const dataObjectId = props.row.dataObjectId;
        const appId = props.row.appId;
        if (dataObjectId) {
            return getDataObjectById(dataObjectId, appId);
        } else {
            return undefined;
        }
    }
});

const _allowDelete = computed(() => {
    return _dataObject.value && (props.softDelete
        ? _dataObject.value.allowUpdate
        : _dataObject.value.allowDelete);
});

const _row = computed(() => {
    return props.row ?? _dataObject.value.current;
});

const isDeleting = computed(() => {
    return _row.value.isDeleting ?? false;
});

const confirmShown = computed(() => {
    return _row.value.o_DeleteConfirm;
});

const isNewRecord = computed(() => {
    return _row.value.isNewRecord;
});

function doDelete() {
    let vConfirm = false;
    if (typeof props.confirm !== 'undefined') {
        if (typeof props.confirm === 'string') {
            vConfirm = props.confirm !== 'false';
        } else {
            vConfirm = props.confirm;
        }
    }
    if (vConfirm && !_dataObject.value.deleteConfirm) {
        const isInModal = !!elementRef.value?.closest('.modal');
        const options = {
            message: props.confirmOptions?.message ?? (
                props.softDelete ? translate('Are you sure you want to mark as deleted?') : translate('Are you sure you want to delete?')
            ),
            title: props.confirmOptions?.title ?? translate('Delete confirm'),
            btnTextOk: props.confirmOptions?.btnTextOk ?? translate('Delete'),
            btnClassOk: props.confirmOptions?.btnClassOk ?? 'btn-primary',
            btnClassCancel: props.confirmOptions?.btnClassCancel ?? 'btn-outline-primary',
            zIndex: isInModal ? 1060 : null
        };
        _row.value.o_DeleteConfirm = true;
        o365_confirm(options).then(() => {
            delete _row.value.o_DeleteConfirm;
            if (props.softDelete) {
                _dataObject.value.softDeleteItem(_row.value);
            } else {
                _dataObject.value.deleteItem(_row.value);
            }
        }).catch(ex => {
            if (ex != null && !ex.Canceled) { logger.error(ex); }
            delete _row.value.o_DeleteConfirm;
        });
    } else {
        if (props.softDelete) {
            _dataObject.value.softDeleteItem(_row.value);
        } else {
            _dataObject.value.deleteItem(_row.value);
        }
    }
}
</script>